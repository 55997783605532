<template>
  <b-container fluid>
    <b-modal id="addGiftcard" :title="$t('extra.add_giftcard')" hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0 row">
        {{ $t('extra.giftcard_name') }} <br>
        <input type="text" class="form-control round mb-4" :placeholder="$t('extra.giftcard_name')" v-model="giftcardName" >
        <div class="row m-0 p-0">
          <div class="col-sm-6 m-0 p-0">
            {{ $t('extra.giftcard_value') }} <br>
            <input type="number" class="form-control round mb-4" :placeholder="$t('extra.giftcard_value')" v-model="giftValue" >
          </div>
          <div class="col-sm-6 m-0 p-0">
            {{ $t('extra.giftcard_value_type') }} <br>
            <b-form-select v-model="giftValueType" style="height: 45px; border-radius: 9px">
              <b-form-select-option value="percent" selected>{{ $t('extra.percent') }}</b-form-select-option>
              <b-form-select-option value="value" selected>{{ $t('extra.realvalue') }}</b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="col-sm-6 m-0 p-0">
            {{ $t('calendar.starting_date') }}<br> <b-form-input v-model="giftcardFrom" type="datetime-local" value="2019-12-19T13:45:00" @change="setEndingDateAndTotalPrice"></b-form-input>
          </div>
          <div class="col-sm-6 m-0 p-0">
            {{ $t('calendar.ending_date') }}<br> <b-form-input v-model="giftcardTo" type="datetime-local"  ></b-form-input>
          </div>
        </div>
        <input type="hidden" v-model="giftcardToken" />
        <div class="col-sm-12 m-0 p-0 text-right">
          <button type="submit" class="btn btn-primary" v-on:click="saveGiftcard">{{ $t('extra.giftcard_save') }}</button>
        </div>
      </div>
    </b-modal>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('extra.giftcards') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="addNewGiftcard">{{ $t('extra.add_giftcard') }}</b-button>
          </template>
          <template v-slot:body>
            <div style="width: 100%">
              <v-text-field
                v-model="search"
                append-icon="ri-search-line"
                :label="$t('extra.giftcard_search')"
                single-line
                hide-details
              ></v-text-field>
            </div>
            <v-data-table
              :headers="headers"
              :items="rowData"
              :search="search"
              :footer-props="{
              prevIcon: 'ri-arrow-left-line',
              nextIcon: 'ri-arrow-right-line'
            }"
            >
              <template v-slot:item.value="{ item }">
                {{ item.value }} {{ item.value_type === '%' ? item.value_type : '' }}
              </template>
              <template v-slot:item.name="{ item }">
                {{ item.name }} <br>
                <span style="font-size:11px;">
                  {{ $t('services.from_date') }}: {{ item.from_date }}, {{ $t('services.to_date') }}: {{ item.to_date }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <button type="button" class="btn btn-default text-success" @click="editGiftcard(item)">
                  <i class="ri-pencil-line"></i> {{ $t('employees_list.edit') }}
                </button>
                <button type="button" class="btn btn-default text-danger" @click="removeData(item.token)">
                  <i class="ri-delete-bin-line"></i> {{ $t('employees_list.delete') }}
                </button>
              </template>
            </v-data-table>

          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'Vouchers List',
  mounted () {
    core.index()
    this.getVouchers()
  },
  data () {
    return {
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      columnDefs: null,
      rowData: [],
      modules: AllCommunityModules,
      headers: [
        { text: this.$t('extra.giftcard_token'), value: 'token' },
        { text: this.$t('extra.giftcard_value'), value: 'value' },
        { text: this.$t('employees_list.name'), align: 'start', sortable: true, value: 'name' },
        { text: this.$t('employees_list.actions'), value: 'actions' }
      ],
      search: '',
      giftcardName: '',
      giftcardFrom: '',
      giftcardTo: '',
      giftValue: '',
      giftValueType: 'percent',
      giftcardToken: ''
    }
  },
  beforeMount () {
  },
  methods: {
    addNewGiftcard () {
      this.giftcardName = ''
      this.giftValue = ''
      this.giftValueType = 'percent'
      this.giftcardFrom = ''
      this.giftcardTo = ''
      this.giftcardToken = ''
      this.$bvModal.show('addGiftcard')
    },
    editGiftcard (giftcard) {
      this.giftcardName = giftcard.name
      this.giftValue = giftcard.value
      this.giftValueType = giftcard.value_type === '%' ? 'percent' : 'value'
      this.giftcardFrom = giftcard.from_date + 'T00:00:00'
      this.giftcardTo = giftcard.to_date + 'T00:00:00'
      this.giftcardToken = giftcard.token
      this.$bvModal.show('addGiftcard')
    },
    saveGiftcard () {
      if (this.giftcardName === '') {
        this.errorCap = this.$t('extra.giftcard_empty_name')
      } else if (this.giftValue === '') {
        this.errorCap = this.$t('extra.giftcard_empty_value')
      } else if (this.giftValueType === '') {
        this.errorCap = this.$t('extra.giftcard_empty_value_type')
      } else if (this.giftcardFrom === '') {
        this.errorCap = this.$t('extra.giftcard_empty_from')
      } else if (this.giftcardTo === '') {
        this.errorCap = this.$t('extra.giftcard_empty_from')
      } else {
        const postData = {
          name: this.giftcardName,
          value: this.giftValue,
          type: this.giftValueType,
          from: this.giftcardFrom,
          to: this.giftcardTo,
          token: this.giftcardToken
        }
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        Axios.post(API.API_ADD_COMPANY_VOUCHER, postData)
          .then((response) => {
            if (response.data.status === 'success') {
              this.rowData = response.data.vouchers
              this.$bvModal.hide('addGiftcard')
              core.showSnackbar('success', this.$t('extra.giftcard_added'))
            }
          })
          .catch((error) => {
            console.error(error.response)
          })
      }
    },
    getVouchers () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_COMPANY_VOUCHER)
        .then((response) => {
          if (response.data.status === 'success') {
            this.rowData = response.data.vouchers
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    removeData (token) {
      this.$bvModal.msgBoxConfirm(this.$t('extra.giftcard_delete_message'))
        .then(value => {
          this.boxOne = value
          if (value === true) {
            const formData = {
              token: token
            }
            Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
            Axios.post(API.API_DELETE_COMPANY_VOUCHER, formData)
              .then((response) => {
                this.$bvModal.hide('addGiftcard')
                if (response.data.status === 'success') {
                  core.showSnackbar('success', this.$t('extra.giftcard_delete_success'))
                }
              })
              .catch((error) => {
                console.error(error.response)
                if (error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                }
              })
          }
        })
    }
  }
}
</script>
